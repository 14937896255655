<mat-toolbar>
    <!-- <span i18n="@@eventTitleForm">Event Title</span> -->
    <span>{{this.event.title}}</span>
</mat-toolbar>
<div class="container">
    <mat-card>
        <section>
            <!-- <mat-card-title i18n="@@noConfirmEmail">
                If you do not receive a confirmation email
            </mat-card-title><br> -->
            <mat-card-title>
                登録してもメールが届かない場合 / <br> If you do not receive a confirmation email
            </mat-card-title><br>
            <mat-card-content>
                <p>これはGeneralなページとして設置します。
                    内容は後で考えます。<br>
                    This will be set up as a General page.
                    The content will be considered later.            
                </p>
            </mat-card-content>
            <!-- <mat-card-actions>
                <button
                mat-flat-button
                (click)="backToConfirmation()"
                class = ""
                i18n="@@goBack"
                color="primary"
                >Back</button>
            </mat-card-actions> -->
        </section>
    </mat-card>
</div>