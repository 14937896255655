import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authServ: AuthService,
    private router: Router,
    private location: Location,
    private snackbar: MatSnackBar
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authServ.user$.pipe(
      filter((user) => {
        if (user === undefined) return false;
        return true;
      }),
      map((user) => {
        console.log("Hello World12", route.url)
        if (!user) return this.router.parseUrl('/login');
        return true;
      }),
      tap((data) => {
        if (data instanceof UrlTree) {
          this.snackbar.open(
            $localize`:@@notLoggedIn:You are not logged in. Please login.`,
            $localize`:@@close:Close`,
            { duration: 4000 }
          );
        }
      })
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Use segments
    return this.authServ.user$.pipe(
      filter((user) => {
        if (user === undefined) return false;
        return true;
      }),
      map((user) => {
        if (!user && this.location.path().includes("attendee")) return this.router.parseUrl('/error');
        if (!user) return this.router.parseUrl('/login');
        return true;
      }),
      tap((data) => {
        if (data instanceof UrlTree) {
          this.snackbar.open(
            $localize`:@@notLoggedIn:You are not logged in. Please login.`,
            $localize`:@@close:Close`,
            { duration: 4000 }
          );
        }
      })
    );
  }
}
