import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public authServ: AuthService, private snackbar: MatSnackBar) {
    this.authServ.user$.pipe(take(1)).subscribe((user) => {
      if (!user) return;
      this.snackbar.open(
        $localize`:@@welcomeBack:Welcome back!`,
        $localize`:@@close:Close`,
        { duration: 4000 }
      );
    });
  }
}
