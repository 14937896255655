import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Event } from '../models/event';
import { Role } from './../models/event-role';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  selectedEvent$ = new BehaviorSubject<Event | null | undefined>(undefined);
  userEventAccess$ = new BehaviorSubject<Role | null>(null);
  events$ = new BehaviorSubject<{ [key: string]: Event }>({});

  sortDetails?: Sort;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  pageIndex = 0;

  notifSort?: string;
  filter = '';

  constructor(private http: HttpClient, private auth: AuthService) {
    // this.refreshEventList();
  }

  resetFilters(): void {
    this.sortDetails = undefined;
    this.pageSize = 10;
    this.pageIndex = 0;
  }

  refreshEventList(): void {
    this.getAllEvents().subscribe();
  }

  getLatestEvent(): Observable<Event | null> {
    return this.events$.pipe(
      take(1),
      map((events) => {
        const lastEventID = localStorage.getItem('lastEvent') != null ? parseInt(localStorage.getItem('lastEvent')!) : null;
        const lastEvent = Object.values(events).find((event)=>{
          return event.id ==  lastEventID;
        })
        if (!!lastEvent) return lastEvent;
        const filtered = Object.values(events).sort((event1, event2) => {
          return event2.created_at.getTime() - event1.created_at.getTime();
        });
        if (filtered.length > 0) return filtered[0];
        return null;
      }),
      tap((event) => {
        this.selectedEvent$.next(event);
        if (!!event) localStorage.setItem('lastEvent', event.id.toString());//last accessed event
      })
    );
  }

  changeUserAccess(): Observable<Role> {
    return this.selectedEvent$.pipe(
      filter((event) => !!event),
      switchMap((event) => {
        return this.auth.user$.pipe(
          filter((user) => !!user),
          take(1),
          switchMap((user) => {
            return this.http
              .get<{ role: Role }>(
                `${environment.apiUrl}/event/${event!.id}/access/${user!.uid}`
              )
              .pipe(take(1));
          })
        );
      }),
      switchMap(({ role }) => {
        return this.auth.user$.pipe(
          filter((user) => !!user),
          map((user) => {
            return user!.is_superuser ? 'admin' : role;
          })
        );
      }),
      tap((role) => {
        this.userEventAccess$.next(role);
      })
    );
  }

  getAllEvents(): Observable<{ [key: string]: Event }> {
    return this.http.get<Event[]>(`${environment.apiUrl}/event`).pipe(
      take(1),
      map((events) => {
        return events.filter((event) => {
          return event.status !== 'archived';
        });
      }),
      map((events) => {
        return events.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: { ...curr, created_at: new Date(curr.created_at) },
          };
        }, {} as { [key: string]: Event });
      }),
      tap((events) => {
        this.events$.next(events);
      })
    );
  }

  addEvent(event: Event, locale: string): Observable<null> {
    return this.http
      .post<null>(`${environment.apiUrl}/event?locale=${locale}`, event)
      .pipe(
        take(1),
        switchMap(() => {
          return this.getAllEvents();
        }),
        map(() => null)
      );
  }

  getEvent(id: number): Observable<Event> {
    return this.http
      .get<Event>(`${environment.apiUrl}/event/${id}`)
      .pipe(take(1));
  }

  editEvent(event: Partial<Event>): Observable<null> {
    return this.http
      .patch<null>(`${environment.apiUrl}/event/${event.id}`, event)
      .pipe(
        take(1),
        switchMap(() => {
          return this.getAllEvents();
        }),
        map(() => null)
      );
  }

  deleteEvent(id: number): Observable<null> {
    return this.http.delete<null>(`${environment.apiUrl}/event/${id}`).pipe(
      take(1),
      switchMap(() => {
        return this.getAllEvents();
      }),
      map(() => null)
    );
  }
}
