import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-bounce-attendee',
  templateUrl: './bounce-attendee.component.html',
  styleUrls: ['./bounce-attendee.component.scss']
})
export class BounceAttendeeComponent implements OnInit {
  eid: string = ""
  hash: string = ""
  event: any = {}
  fromConfirmation: boolean = false
  fgAttendee = this.fb.group({
    description: this.fb.control('', []),
    surname: this.fb.control('', [Validators.required]),
    given_name: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required, Validators.email]),
    company: this.fb.control('', [Validators.required]),
    division: this.fb.control('', []),
    title: this.fb.control('', []),
    postcode: this.fb.control('', [Validators.required]),
    address: this.fb.control('', [Validators.required]),
    phone: this.fb.control('', []),
    personal_info:this.fb.control('', [Validators.required])
  });

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private eventServ: EventService,
  ) { }

  ngOnInit(): void {
  

    this.ar.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }
        // this.fgAttendee.value.surname1 = params["username"]
        this.eid = params["eid"],
        this.hash = params["hash"]
        this.fromConfirmation = params["fromConfirmation"]
        // this.order = params.order;
        this.fgAttendee.patchValue({
          surname : params["surname"],
          given_name : params["given_name"],
          email : params["email"],
          company : params["company"],
          division : params["division"],
          title : params["title"],
          postcode : params["postcode"],
          address : params["address"],
          phone : params["phone"],
        })
        // console.log(this.order); // popular
      }
    );

    this.eventServ.getEvent(parseInt(this.eid)).subscribe((res) => {
      this.event = res;
    });
    
    this.location.replaceState("/info/bounce");

  }

  back(): void {

    this.router.navigate(['sign-up/:code'])

  }

  backToConfirmation(): void{
    console.log("Back to confirmation:", typeof this.fromConfirmation)
    if(this.fromConfirmation === true){
      // this.router.navigate(['sign-up/:code'])
      this.router.navigate([`/registrant/${this.eid}-${this.hash}/confirm`],
      { state: { formData: this.fgAttendee.value } });
    } else {
    // this.router.navigate(['sign-up/:code'])
      this.router.navigate([`/sign-up/${this.eid}-${this.hash}`],
    { state: { formData: this.fgAttendee.value } });
    }
    console.log("Hello World")
}

}
