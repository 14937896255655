export class User {
  constructor(
    public email: string,
    public uid: number,
    private _token: string,
    public expiration: Date,
    public is_superuser = false
  ) {}

  get token(): string | null {
    if (this.expiration < new Date()) {
      return null;
    }
    return this._token;
  }

  setToken(token: string, expiration: Date): void {
    this._token = token;
    this.expiration = expiration;
  }
}
