import { Component, OnInit, Inject, LOCALE_ID, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BreakpointService } from './../../../../../services/breakpoint.service';
import { GuestService } from './../../../../../services/guest.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'app-new-attendee-confirm',
  templateUrl: './new-attendee-confirm.component.html',
  styleUrls: ['./new-attendee-confirm.component.scss']
})
export class NewAttendeeConfirmComponent implements OnInit {
  // @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;
  loading = false;
  emails: string[] = [];
  eid: string = ""
  hash: string = ""
  event: any = {}
  constructor(
    // @Inject(MAT_DIALOG_DATA) public guest:  & { emails: string }
    private router: Router,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    public bs: BreakpointService,
    private guestServ: GuestService,
    private location: Location,
    private authServ: AuthService,
    private snackbar: MatSnackBar,
    @Inject(LOCALE_ID) private locale: string,
    private sanitizer: DomSanitizer


  ) {}
  fgAttendee = this.fb.group({
    description: this.fb.control('', []),
    surname: this.fb.control('', [Validators.required]),
    given_name: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required, Validators.email]),
    company: this.fb.control('', [Validators.required]),
    division: this.fb.control('', []),
    title: this.fb.control('', []),
    postcode: this.fb.control('', [Validators.required]),
    address: this.fb.control('', [Validators.required]),
    phone: this.fb.control('', []),
    personal_info:this.fb.control('', [Validators.required])
  });
  fgPersonalInfo = this.fb.control('')

  ngOnInit(): void {
    this.event = history.state.event;
    this.fgPersonalInfo.patchValue(this.sanitizer.bypassSecurityTrustHtml(this.event.s_settings?.privacy_policy)); 
    this.ar.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }
        // this.fgAttendee.value.surname1 = params["username"]
        this.fgAttendee.patchValue({
          surname : params["surname"],
          given_name : params["given_name"],
          email : params["email"],
          company : params["company"],
          division : params["division"],
          title : params["title"],
          postcode : params["postcode"],
          address : params["address"],
          phone : params["phone"],
        })
        // this.order = params.order;

        // console.log(this.order); // popular
      }
    );

    this.ar.params.subscribe(({ code }) => {
      console.log("CODE4: ", code)
      const eid = code.split("-")[0]
      const hash = code.split("-")[1]

      const hashToCompare = this.authServ.hashString(eid)

      if (hash !== hashToCompare) {
        console.log("Error Hash1")
        this.router.navigate(['/error'])
        // this.location.replaceState("/some/newstate/");

      }
      this.eid = eid;
      this.hash = hash;

      console.log("HASH:", this.authServ.hashString(eid))
    });

    this.location.replaceState("/attendee-registration/confirm");
    // this.scrollContainer.nativeElement.scrollTop = 0;
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const bodyElement = document.querySelector('.screen-body');
        if (bodyElement) {
          bodyElement.scrollTop = 0;
        }
      }
    });
  }
  onSubmit(form: FormGroupDirective): void {
    console.log("test")
    this.loading = true;
    console.log(this.fgAttendee.value)
    this.guestServ
    .registerGuest({
      ...this.fgAttendee.value
    }, this.eid, this.locale)
    .pipe(
      map((mapData) => {
        console.log("MAP DATA:" , mapData)
      }),
      tap(() => (this.loading = false))
    ).subscribe(
      () =>{
        console.log("Success")
        this.router.navigate([`new-attendee/registration/success`],
        { state: { event:this.event} })
      },
      (error) =>{
        console.log("error")
        this.snackbar.open(
          $localize`:@@wentWrong:Something went wrong. Please reload the page.`,
          $localize`:@@close:Close`,
          { duration: 4000 }
        );
      },
      () =>{
        console.log("Complete")
        
      }
    );
  }
  

  bounce(): void {
    // this.router.navigate(['info/bounce'],
    // {
    //   queryParams: {
    //     eid:this.eid,
    //     hash:this.hash,
    //     fromConfirmation: true,
    //     surname: this.fgAttendee.value.surname,
    //       given_name: this.fgAttendee.value.given_name,
    //       email: this.fgAttendee.value.email,
    //       company: this.fgAttendee.value.company,
    //       division: this.fgAttendee.value.division,
    //       title: this.fgAttendee.value.title,
    //       postcode: this.fgAttendee.value.postcode,
    //       address: this.fgAttendee.value.address,
    //       phone: this.fgAttendee.value.phone,
    //   }
    // });
    window.open('info/bounce?eid='+this.eid, '_blank');
  }

  goBack() {
    this.router.navigate([`/sign-up/${this.eid}-${this.hash}`],
    { state: { formData: this.fgAttendee.value } });
  }
 
  backToConfirmation(): void{
        console.log("Hello World")
  }

}
