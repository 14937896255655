import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { validateEmail } from 'src/app/models/functions';
import { BreakpointService } from './../../../../services/breakpoint.service';
import { GuestService } from 'src/app/services/guest.service';
import { NewAttendeeConfirmComponent } from './new-attendee-confirm/new-attendee-confirm.component';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import {DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'app-new-attendee',
  templateUrl: './new-attendee.component.html',
  styleUrls: ['./new-attendee.component.scss'],
})
export class NewAttendeeComponent implements OnInit {
  @ViewChild(MatChipList) chiplist!: MatChipList;
  fgAttendee = this.fb.group({
    description: this.fb.control(''),
    surname: this.fb.control('', [Validators.required]),
    given_name: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required, Validators.email]),
    company: this.fb.control('', [Validators.required]),
    division: this.fb.control(''),
    title: this.fb.control(''),
    postcode: this.fb.control('', [Validators.required]),
    address: this.fb.control('', [Validators.required]),
    phone: this.fb.control(''),
    personal_info: this.fb.control('', [Validators.required]),
  });
  loading = false;
  isAgreeChecked: boolean = false;

  fgDescription = this.fb.control('')
  fgPersonalInfo = this.fb.control('')

  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  eid: string = ""
  hash: string = ""
  event: any = {}
  // eid: Observable<any> = this.ar.params.pipe(switchMap(({eid}) => {return eid}));
  // code: Observable<any> = this.ar.params.pipe(switchMap(({code}) => {return code}));

  constructor(
    private fb: FormBuilder,
    private guestServ: GuestService,
    private eventServ: EventService,
    private snackbar: MatSnackBar,
    private ar: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public bs: BreakpointService,
    private location: Location,
    private authServ: AuthService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    // const { eid, code } = this.ar.params;
    // console.log("EID: ", eid, "CODE: ", code)
    // this.ar.params.pipe(switchMap((params: Params) => {
    //   this.eid = eid;
    //   this.code = code;
    //   return
    // }));

    // this.code = this.ar.params.pipe(switchMap(({code}) => {return code}));
    // console.log("CODE CODE: ", this.code)

    this.ar.params.subscribe(({ code }) => {
      // console.log("EID3: ", eid, "CODE3: ", code)
      const eid = code.split("-")[0]
      const hash = code.split("-")[1]

      const hashToCompare = this.authServ.hashString(eid)

      if (hash !== hashToCompare) {
        console.log("Error Hash0")
        this.router.navigate(['/error'])
        // this.location.replaceState("/some/newstate/");

      }

      this.eid = eid;
      this.hash = hash;

      console.log("HASH:", this.authServ.hashString(eid))
    });
    // console.log("LOGGER:", this.code)
    console.log("EID3: ", this.eid, "CODE3: ", this.hash)

    this.eventServ.getEvent(parseInt(this.eid)).subscribe((res) => {
      this.event = res;
      
      //Error if self registration is off
      // console.log("Self Reg", typeof this.event.s_settings.opt_self_registration )
      if (!this.event.s_settings?.opt_self_registration) this.router.navigate(['/error'])
      //

      //Convert to HTML
      const hexCodeRegex = /(#[A-Fa-f0-9]{6})/g;

      this.event.s_settings.description = this.event.s_settings.description
      .replace(/({{)/g, "<b>")
      .replace(/(}})/g, "</b>")
      .replace(/(\[\[)/g, "")
      .replace(hexCodeRegex, '<span style="color: $1">')
      .replace(/(\]\])/g, "</span>")
      .replace("%%EVENT_NAME%%", this.event.title)
      .replace(/\n/g, '<br />')

      this.event.s_settings.privacy_policy = this.event.s_settings.privacy_policy
      .replace(/({{)/g, "<b>")
      .replace(/(}})/g, "</b>")
      .replace(/(\[\[)/g, "")
      .replace(hexCodeRegex, '<span style="color: $1">')
      .replace(/(\]\])/g, "</span>")
      .replace("%%EVENT_NAME%%", this.event.title)
      .replace(/\(\((.*?)(?=(\s*)@)/g, '<a href="$1" target="_blank">')
      .replace(/\@(.*?)\@/g, '$1')
      .replace(/(\)\))/g, '</a>')
      .replace(/\n/g, '<br />')
      //Convert to HTML

      

      console.log("Text:", this.event.s_settings?.description )

      this.fgDescription.patchValue(this.sanitizer.bypassSecurityTrustHtml(this.event.s_settings?.description)); 
      this.fgPersonalInfo.patchValue(this.sanitizer.bypassSecurityTrustHtml(this.event.s_settings?.privacy_policy)); 
    });

    this.fgAttendee.patchValue({
      surname: history.state.formData?.surname,
      given_name: history.state.formData?.given_name,
      email: history.state.formData?.email,
      company: history.state.formData?.company,
      division: history.state.formData?.division,
      title: history.state.formData?.title,
      postcode: history.state.formData?.postcode,
      address: history.state.formData?.address,
      phone: history.state.formData?.phone,
    })

    // this.ar.queryParams
    //   .subscribe(params => {
    //     console.log(params); // { order: "popular" }
    //     // this.fgAttendee.value.surname = params["username"]
    //     this.fgAttendee.patchValue({
    //       ...params,
    //     })
    //     // this.order = params.order;

    //     // console.log(this.order); // popular
    //   }
    // );
    // console.log("EID2: ", this.eid, "CODE2: ", this.code)
    this.location.replaceState("/attendee-registration");
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const bodyElement = document.querySelector('.screen-body');
        if (bodyElement) {
          bodyElement.scrollTop = 0;
        }
      }
    });
  }

  onSubmit(form: FormGroupDirective): void {
    // if (this.fgAttendee.invalid) return;
    this.loading = true;
    this.fgAttendee.disable();
    if (this.fgAttendee.invalid) return;
    console.log("fgAttendee:")
    this.router.navigate([`/registrant/${this.eid}-${this.hash}/confirm`],
      {
        queryParams: {
          surname: this.fgAttendee.value.surname,
          given_name: this.fgAttendee.value.given_name,
          email: this.fgAttendee.value.email,
          company: this.fgAttendee.value.company,
          division: this.fgAttendee.value.division,
          title: this.fgAttendee.value.title,
          postcode: this.fgAttendee.value.postcode,
          address: this.fgAttendee.value.address,
          phone: this.fgAttendee.value.phone,
        },
        state:{
          event: this.event
        }
      });

      

    // const dialogRef = this.dialog.open(NewAttendeeConfirmComponent, {
    //   data: { ...this.fgAttendee.value, emails: this.emails.join(',') },
    // });

    // dialogRef
    //   .afterClosed()
    //   .pipe(
    //     map((data) => {
    //       if (data === undefined) return [false, true];
    //       if (data === false) return [true, false];
    //       return [true, true];
    //     }),
    //     switchMap(([shouldAdd, shouldContinue]) => {
    //       if (!shouldContinue)
    //         this.router.navigate(['../..'], { relativeTo: this.ar });
    //       if (!!shouldAdd)
    //         return this.guestServ
    //           .addGuest({
    //             ...this.fgAttendee.value,
    //             alert_to: this.emails.join(','),
    //           })
    //           .pipe(
    //             map(() => true),
    //             tap(() => (this.loading = false))
    //           );
    //       return of(null);
    //     }),
    //     take(1),
    //     filter((data) => !!data)
    //   )
    //   .subscribe(
    //     () => {
    //       this.snackbar.open(
    //         $localize`:@@successfullyAdded:Successfully added ${this.fgAttendee.value.surname} ${this.fgAttendee.value.given_name}.`,
    //         $localize`:@@close:Close`,
    //         { duration: 4000 }
    //       );

    //       form.resetForm({
    //         description: '',
    //         surname: '',
    //         given_name: '',
    //         email: '',
    //         company: '',
    //         division: '',
    //         title: '',
    //         postcode: '',
    //         address: '',
    //         phone: '',
    //         personal_info: 'indeterminate',
    //       });
    //     },
    //     (err) => {
    //       this.snackbar.open(
    //         $localize`:@@wentWrong:Something went wrong. Please reload the page.`,
    //         $localize`:@@close:Close`,
    //         { duration: 4000 }
    //       );
    //     },
    //     () => {
    //       this.loading = false;
    //       this.fgAttendee.enable();
    //     }
    //   );
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const email = (event.value || '').trim();

    if (!validateEmail(email)) {
      this.fgAttendee.controls['alert_to'].setErrors({ err: true });
      this.chiplist.errorState = true;
      return;
    }

    this.fgAttendee.controls['alert_to'].setErrors(null);
    this.chiplist.errorState = false;

    if (email && !this.emails.includes(email)) {
      this.emails.push(email);
    }

    event.chipInput!.clear();
    this.fgAttendee.controls['alert_to'].setValue(null);
  }

  attendeeRegistration(): void {
    this.router.navigate(['new-attendee-confirm'])
  }

  bounce(): void {

    // this.router.navigate([`info/bounce`],
    //   {
    //     queryParams: {
    //       eid: this.eid,
    //       hash:this.hash,
    //       fromConfirmation: false,
    //       surname: this.fgAttendee.value.surname,
    //       given_name: this.fgAttendee.value.given_name,
    //       email: this.fgAttendee.value.email,
    //       company: this.fgAttendee.value.company,
    //       division: this.fgAttendee.value.division,
    //       title: this.fgAttendee.value.title,
    //       postcode: this.fgAttendee.value.postcode,
    //       address: this.fgAttendee.value.address,
    //       phone: this.fgAttendee.value.phone,
    //     }
    //   });
    window.open('info/bounce?eid='+this.eid, '_blank');
  }


}