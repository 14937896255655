import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  fgResetPass = this.fb.group({
    email: this.fb.control(this.data.email, [
      Validators.required,
      Validators.email,
    ]),
  });

  constructor(
    private authServ: AuthService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { email: string },
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private snackbar: MatSnackBar,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.fgResetPass.invalid) return;
    this.authServ
      .forgotPassword(this.fgResetPass.value.email, this.locale)
      .subscribe(
        () => {
          this.snackbar.open(
            $localize`:@@emailSent:Email has been sent! Please check your inbox.`,
            $localize`:@@close:Close`,
            { duration: 4000 }
          );
          this.dialogRef.close();
        },
        () => {
          this.snackbar.open(
            $localize`:@@error:Something went wrong, try again.`,
            $localize`:@@close:Close`,
            { duration: 3000 }
          );
        }
      );
  }
}
