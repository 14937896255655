import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Account } from './../models/account';
import { SHA256 } from 'crypto-js';

interface JWT {
  uid: number;
  is_superuser: boolean;
  exp: number;
  iat: number;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$ = new BehaviorSubject<User | null | undefined>(undefined);
  notifLogout = new Subject<number>();
  expirationTimer?: any;
  force$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    const user = localStorage.getItem('user') || '';
    const { token, email } = JSON.parse(user || '{}') as {
      token: string;
      email: string;
    };
    const parsedJWT = this.parseJWT(token);

    if (!token || !parsedJWT || !email) {
      this.user$.next(null);
      return;
    }
    this.assignUser(email, token, parsedJWT).subscribe();
  }

  assignUser(
    email: string,
    token: string,
    parsedJWT: JWT
  ): Observable<boolean> {
    return this.force$.pipe(
      take(1),
      tap((force) => {
        let { exp, is_superuser, uid } = parsedJWT;
        const expiration = new Date(0);
        expiration.setUTCSeconds(exp);

        const user = new User(email, uid, token, expiration, is_superuser);

        if (!user.token) {
          localStorage.removeItem('user');
          this.user$.next(null);
          return;
        }

        // this.setTimer(user);
        if (!force)
          localStorage.setItem('user', JSON.stringify({ email, token }));

        this.user$.next(user);
      })
    );
  }

  login(email: string, password: string): Observable<User | null | undefined> {
    return this.http
      .post<{ token: string } | null>(
        `${environment.apiUrl}/login`,
        { email, password },
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        take(1),
        map((res) => {
          const token = res.headers.get('authorization');
          return token;
        }),
        switchMap((token) => {
          if (!token) {
            this.user$.next(null);
            return of();
          }
          const parsedJWT = this.parseJWT(token);

          if (!parsedJWT) {
            this.user$.next(null);
            return of();
          }

          return this.assignUser(email, token, parsedJWT);
        }),
        switchMap(() => {
          return this.user$.pipe(take(1));
        })
      );
  }

  loginWithToken(token: string): Observable<User | null | undefined> {
    const parsedToken = this.parseJWT(token);
    if (!parsedToken) return of(null).pipe(take(1));

    return this.http
      .get<{ force: boolean }>(`${environment.apiUrl}/login/${token}`, {
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res) => {
          const token = res.headers.get('authorization');
          const force = !!res.body?.force;
          return [token, force] as [string, boolean];
        }),
        switchMap(([token, force]) => {
          if (!token) {
            this.user$.next(null);
            return of();
          }
          let parsedJWT = this.parseJWT(token);

          if (!parsedJWT) {
            this.user$.next(null);
            return of();
          }

          this.force$.next(force);
          return this.assignUser(parsedToken.email, token, parsedJWT);
        }),
        switchMap(() => {
          return this.user$.pipe(take(1));
        })
      );
  }

  updateAccount(id: number, account: Partial<Account>): Observable<null> {
    return this.http
      .patch<{ force: boolean }>(`${environment.apiUrl}/account/${id}`, account)
      .pipe(
        take(1),
        switchMap(({ force }) => {
          if (force === undefined) return of();
          return this.user$.pipe(
            take(1),
            filter((user) => !!user),
            switchMap((user) => {
              if (!user) return of();
              const { email, token } = user;
              let jwt = this.parseJWT(token!);
              if (!jwt) return of();
              this.force$.next(force);

              return this.assignUser(email, token!, jwt);
            })
          );
        }),
        map(() => null)
      );
  }

  logout(shouldNotify = true): void {
    localStorage.removeItem('user');
    localStorage.removeItem('tid');
    this.user$.next(null);
    if (shouldNotify) this.notifLogout.next();
  }

  forgotPassword(email: string, locale: string): Observable<null> {
    return this.http
      .get<null>(`${environment.apiUrl}/login`, {
        params: { email, locale },
      })
      .pipe(take(1));
  }

  // setTimer(user?: User): void {
  //   if (!!this.expirationTimer) clearTimeout(this.expirationTimer);
  //   if (!user) return;

  //   const timeout = new Date(
  //     user.expiration.getTime() -
  //       new Date().getTime() -
  //       environment.timeToLogout
  //   );

  //   this.expirationTimer = setTimeout(() => {
  //     this.notifLogout.next(10);
  //   }, timeout.getTime());
  // }

  parseJWT(token: string): JWT | null {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(payload);
    } catch (err) {
      return null;
    }
  }

  hashString(str: string): string {
    return SHA256(str).toString();
  }
}
