<mat-toolbar>
    <span i18n="@@eventTitleForm">{{this.event.title}}</span>
</mat-toolbar>
<div class="container">
    <mat-card>
        <section>
            <!-- <mat-card-title i18n="@@registeredAttendee"> -->
            <mat-card-title class="line-height">
                ご登録いただきありがとうございます / <br> Thank you for registering
            </mat-card-title><br>
            <p class="line-height">    
                登録後、数時間以内にご登録いただいたアドレスにQRコードを記載したメールが送付されます。
                万が一、メールが届かない場合は、<a (click)="bounce()" class="clickable-link"><u>こちらのページをご参照ください。</u></a> <br>
                After registration, an email with the QR code will be sent to your registered address within a few hours.
                <a (click)="bounce()" class="clickable-link"><u>In case you do not receive the e-mail, please refer to this page.</u></a>
            </p>
            <!-- <mat-card-content>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </mat-card-content> -->
            <!-- <mat-card-actions>
                <button
                mat-flat-button
                (click)="backToConfirmation()"
                class = ""
                i18n="@@goBack"
                color="primary"
                >Back</button>
            </mat-card-actions> -->
        </section>
    </mat-card>
</div>