import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap, filter, take, tap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authServ: AuthService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authServ.user$.pipe(
      filter((user) => user !== undefined),
      take(1),
      switchMap((user) => {
        if (!!user) {
          const token = user.token;
          if (!!token)
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            });
        }

        return next.handle(request);
      }),
      tap((event) => {
        if (!(event instanceof HttpResponse)) return;
        
        const token = event.headers.get('authorization');
        if (!token) return;
        
        this.authServ.user$.pipe(take(1)).subscribe((user) => {
          const parsedJWT = this.authServ.parseJWT(token);
          if (!user || !parsedJWT) {
            this.authServ.logout();
            return;
          }
          this.authServ.assignUser(user.email, token, parsedJWT);
        });
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403 || err.status === 401)
            this.authServ.logout();
        }
      })
    );
  }
}
