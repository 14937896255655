import { EventService } from 'src/app/services/event.service';
import { of, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BreakpointService } from './../../services/breakpoint.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { filter, map, take, switchMap, catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  fgLogin = this.fb.group({
    email: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [Validators.required]),
  });

  subs: Subscription[] = [];

  loading = false;
  invalidCredentials = false;

  constructor(
    private fb: FormBuilder,
    private authServ: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    public bs: BreakpointService,
    private ar: ActivatedRoute,
    private eventServ: EventService
  ) {}

  ngOnInit(): void {
    this.subs = [
      this.ar.queryParams
        .pipe(
          take(1),
          map(({ token }) => token),
          filter((token) => !!token),
          switchMap((token) => {
            return this.authServ.loginWithToken(token);
          }),
          tap((user) => {
            if (!user) return;

            this.invalidCredentials = false;
            this.snackbar.open(
              $localize`:@@welcomeUser:Hi! Welcome, ${user.email}!`,
              $localize`:@@close:Close`,
              { duration: 4000 }
            );
          }),
          switchMap((user) => {
            return this.authServ.force$.pipe(
              take(1),
              tap((force) => {
                // if (force) {
                this.router.navigate([`/account`]);
                return;
                // }
              }),
              // filter((force) => !force),
              switchMap(() => {
                return this.eventServ.getAllEvents().pipe(
                  switchMap(() => {
                    return this.eventServ.getLatestEvent();
                  }),
                  // tap((event) => {
                  //   if (!event) {
                  //     this.router.navigate(['events']);
                  //     return;
                  //   }
                  //   this.router.navigate([`events/${event.id}`]);
                  // }),
                  map(() => user)
                );
              })
            );
          })
        )
        .subscribe(
          () => {},
          () => {
            this.invalidCredentials = true;
          },
          () => {
            this.loading = false;
          }
        ),
    ];
  }

  onSubmit(): void {
    if (this.fgLogin.invalid) return;
    const { email, password } = this.fgLogin.value;
    this.loading = true;

    this.authServ
      .login(email, password)
      .pipe(
        take(1),
        catchError((err) => {
          this.invalidCredentials = true;
          this.loading = false;
          return of(null);
        }),
        filter((user) => !!user),
        switchMap((user) => {
          return this.eventServ.getAllEvents().pipe(
            switchMap(() => {
              return this.eventServ.getLatestEvent();
            }),
            tap((event) => {
              if (!event) {
                this.router.navigate(['events']);
                return;
              }
              this.router.navigate([`events/${event.id}`]);
            }),
            map(() => user)
          );
        }),
        tap((user) => {
          if (!user) return;
          this.invalidCredentials = false;
          this.snackbar.open(
            $localize`:@@welcomeBack:Welcome back!`,
            $localize`:@@close:Close`,
            { duration: 4000 }
          );
        })
      )
      .subscribe();
  }

  resetPassword(): void {
    this.dialog.open(ResetPasswordComponent, {
      data: { email: this.fgLogin.value.email },
    });
  }

  // attendeeRegistration(): void {
  //   this.router.navigate(['new-attendee'])
  // }
}
