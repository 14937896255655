import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator'
import { MyPaginatorIntl } from './my-paginator-intl';
import { NewAttendeeComponent } from './components/login/attendee-registration/new-attendee/new-attendee.component';
import { BounceAttendeeComponent } from './components/login/attendee-registration/bounce-attendee/bounce-attendee.component';
import { NewAttendeeConfirmComponent } from './components/login/attendee-registration/new-attendee/new-attendee-confirm/new-attendee-confirm.component';
import { NewAttendeeSuccessComponent } from './components/login/attendee-registration/new-attendee/new-attendee-success/new-attendee-success/new-attendee-success.component';
import { ErrorScreenComponent } from './components/login/error-screen/error-screen/error-screen.component'
import { NgxYubinBangoModule } from 'ngx-yubinbango';
@NgModule({
  declarations: [AppComponent, LoginComponent, ResetPasswordComponent, NewAttendeeComponent, BounceAttendeeComponent, NewAttendeeConfirmComponent, NewAttendeeSuccessComponent, ErrorScreenComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgxYubinBangoModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: MyPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
