import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { EventGuard } from './guards/event.guard';
import { NewAttendeeComponent } from './components/login/attendee-registration/new-attendee/new-attendee.component';
import { NewAttendeeConfirmComponent } from './components/login/attendee-registration/new-attendee/new-attendee-confirm/new-attendee-confirm.component';
import { BounceAttendeeComponent } from './components/login/attendee-registration/bounce-attendee/bounce-attendee.component';
import { NewAttendeeSuccessComponent } from './components/login/attendee-registration/new-attendee/new-attendee-success/new-attendee-success/new-attendee-success.component';
import { ErrorScreenComponent } from './components/login/error-screen/error-screen/error-screen.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'error',
    component: ErrorScreenComponent
  },
  //Jinno: Add path here called signup then new-attendee as child and confirm as grandchild (maybe)
  //try :eid-:code in path to see if it can read both eid and code
  // {
  //   path: 'registration',
  //   // component: NewAttendeeComponent,
  //   //Jinno: put the new attendee-confirm here as a child path
  //   // child path should also contain :eid and eventguard to set the event id so that it is no longer null
  //   // 
  //   // canActivate: [true],
  //   children: [
  //     {
  //       path: ':eid',
  //       // component: NewAttendeeConfirmComponent,
  //       canActivate: [EventGuard],
  //       // canActivate: [true],

  //       pathMatch: 'full',
  //       children: [
  //         {
  //           path: 'new',
  //           component: NewAttendeeComponent,
  //           pathMatch: 'full',
  //           // canActivate: [true],

  //           children: [
  //             {
  //               path: 'confirm',
  //               component: NewAttendeeConfirmComponent,
  //               pathMatch: 'full',
  //               // canActivate: [LoginGuard],
  //             },
  //             {
  //               path: 'bounce',
  //               component: BounceAttendeeComponent,
  //               pathMatch: 'full',
  //               // canActivate: [LoginGuard],
  //             },
  //           ]
  //           // canActivate: [LoginGuard],
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   path: 'sign-up',
  //   // component: NewAttendeeConfirmComponent,
  //   // canActivate: [LoginGuard],
  //   // pathMatch: 'full',

  //   // children: [
  //   //   {
  //   //     path: ':code',
  //   //     component: NewAttendeeComponent,
  //   //     pathMatch: 'full',
  //   //     // canActivate: [EventGuard],
  //   //     // canActivate: [LoginGuard],
  //   //     // children: [
  //   //     //   {
  //   //     //     path: 'confirm',
  //   //     //     component: NewAttendeeConfirmComponent,
  //   //     //     pathMatch: 'full',
  //   //     //     // canActivate: [EventGuard],
  //   //     //     // canActivate: [LoginGuard],
  //   //     //   },
  //   //     //   {
  //   //     //     path: 'bounce',
  //   //     //     component: BounceAttendeeComponent,
  //   //     //     pathMatch: 'full',
  //   //     //     // canActivate: [EventGuard],
  //   //     //     // canActivate: [LoginGuard],
  //   //     //   },
  //   //     // ]
  //   //   },
  //   //   {
  //   //     path: 'confirm',
  //   //     component: NewAttendeeConfirmComponent,
  //   //     // pathMatch: 'full',
  //   //     pathMatch: 'prefix',
  //   //     // canActivate: [EventGuard],
  //   //     // canActivate: [LoginGuard],
  //   //   },
  //   //   {
  //   //     path: 'bounce',
  //   //     component: BounceAttendeeComponent,
  //   //     // pathMatch: 'full',
  //   //     pathMatch: 'prefix',
  //   //     // canActivate: [EventGuard],
  //   //     // canActivate: [LoginGuard],
  //   //   },
  //   // ]
  // },
  {
    path: 'sign-up/:code',
    component: NewAttendeeComponent,
    pathMatch: 'full',
    // canActivate: [EventGuard],
    // canActivate: [LoginGuard],
    // children: [
    //   {
    //     path: 'confirm',
    //     component: NewAttendeeConfirmComponent,
    //     pathMatch: 'full',
    //     // canActivate: [EventGuard],
    //     // canActivate: [LoginGuard],
    //   },
    //   {
    //     path: 'bounce',
    //     component: BounceAttendeeComponent,
    //     pathMatch: 'full',
    //     // canActivate: [EventGuard],
    //     // canActivate: [LoginGuard],
    //   },
    // ]
  },
  {
    path: 'registrant/:code/confirm',
    component: NewAttendeeConfirmComponent,
    // pathMatch: 'full',
    // canActivate: [EventGuard],
    // canActivate: [LoginGuard],
  },
  {
    path: 'info/bounce',
    component: BounceAttendeeComponent,
    // pathMatch: 'full',
    // canActivate: [EventGuard],
    // canActivate: [LoginGuard],
  },
  {
    path: 'new-attendee/registration/success',
    component: NewAttendeeSuccessComponent,
    // pathMatch: 'full',
    // canActivate: [EventGuard],
    // canActivate: [LoginGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./components/authenticated/main/main.module').then(
        (m) => m.MainModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
