<div class="container">
  <h1 mat-dialog-title i18n="@@forgotPass">Forgot Password</h1>
  <form [formGroup]="fgResetPass" (submit)="onSubmit()">
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label i18n="@@email">Email</mat-label>
        <input
          matInput
          formControlName="email"
        />
        <mat-error
          *ngIf="fgResetPass.controls['email'].invalid"
          i18n="@@emailRequired"
        >
          Email is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="for-buttons" mat-dialog-actions>
      <button
        mat-flat-button
        type="button"
        color="accent"
        [mat-dialog-close]
        class="cbutton"
        i18n="@@cancel"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="fgResetPass.invalid"
        class="rbutton"
        i18n="@@reset"
      >
        Reset
      </button>
    </div>
  </form>
</div>
