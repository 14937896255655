import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-attendee-success',
  templateUrl: './new-attendee-success.component.html',
  styleUrls: ['./new-attendee-success.component.scss']
})
export class NewAttendeeSuccessComponent implements OnInit {
  event: any = {}
  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.event = history.state.event;
    this.location.replaceState("/attendee-registration");
  }
  bounce(): void {
    // this.router.navigate(['info/bounce'],
    // {
    //   queryParams: {
    //     eid:this.eid,
    //     hash:this.hash,
    //     fromConfirmation: true,
    //     surname: this.fgAttendee.value.surname,
    //       given_name: this.fgAttendee.value.given_name,
    //       email: this.fgAttendee.value.email,
    //       company: this.fgAttendee.value.company,
    //       division: this.fgAttendee.value.division,
    //       title: this.fgAttendee.value.title,
    //       postcode: this.fgAttendee.value.postcode,
    //       address: this.fgAttendee.value.address,
    //       phone: this.fgAttendee.value.phone,
    //   }
    // });
    window.open('info/bounce?eid=' + this.event.id, '_blank');
  }
}
