<div class="page">
  <div class="container" [class.mobile]="bs.isHandset$ | async">
    <p class="for-title">
      <mat-card-header class="title">
        <mat-card-title i18n="@@login">Login</mat-card-title>
      </mat-card-header>
    </p>
    <form [formGroup]="fgLogin" (submit)="onSubmit()">
      <mat-card-content>
        <p>
          <mat-form-field class="field">
            <mat-label i18n="@@email" class="label">Email</mat-label>
            <input
              matInput
              formControlName="email"
              class="label"
            />
            <mat-error
              *ngIf="fgLogin.controls['email'].invalid"
              i18n="@@emailRequired"
            >
              Email is required
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="field">
            <mat-label i18n="@@password" class="label">Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="password"
              class="label"
            />
            <mat-error
              *ngIf="fgLogin.controls['password'].invalid"
              i18n="@@passwordRequired"
            >
              Password is required
            </mat-error>
          </mat-form-field>
        </p>
        <div class="invalid-lgin">
          <div *ngIf="invalidCredentials" i18n="@@invalidEmailPass">
            Invalid Email or Password
          </div>
        </div>

        <div class="for-buttons">
          <button
            mat-stroked-button
            type="submit"
            [disabled]="fgLogin.invalid"
            class="lbutton"
            i18n="@@login"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Login
          </button>
          <br />
          <button
            mat-stroked-button
            type="button"
            (click)="resetPassword()"
            class="rbutton"
            i18n="@@passReset"
          >
            Password Reset
          </button>
          <!-- <button
          mat-stroked-button
          type="button"
          (click)="attendeeRegistration()"
          class="rbutton"
          i18n="@newAttendee"
        >
          Self-Registration of Event Attendee
        </button> -->
        </div>
      </mat-card-content>
    </form>
  </div>
</div>
