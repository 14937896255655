<body class="screen-body">
<mat-toolbar>
  <!-- <span i18n="@@eventTitleForm">Event Title</span> -->
  <span>{{ this.event.title }}</span>
</mat-toolbar>
<!-- <mat-card-content>
  <div class="field-container">
    <mat-form-field>
      <mat-label>ID</mat-label>
      <input
        matInput
        formControlName="surname"
        [readonly]="true"
      />
    </mat-form-field>
  </div>     
</mat-card-content> -->
<div class="container">
  <mat-card>
    <form [formGroup]="fgAttendee" #form="ngForm" (submit)="onSubmit(form)">
      <!-- <mat-card-title i18n="@@confirmationScreen">
        Confirmation Screen
      </mat-card-title> -->
      <mat-card-title>
        確認画面 / Confirmation Screen
      </mat-card-title>
      <!-- <a 
      (click)="bounce()"
      i18n="@@checkContentsRegister"
      >※Please check the contents and click the Register button. <br>
      After registration, an e-mail with the QR code will be sent to your registered address within a few hours. <br>
      In case you do not receive the e-mail, please refer to this page.</a> -->
      <a class="confirm-text">※内容をご確認の上、登録ボタンをクリックしてください。
      登録後、数時間以内にご登録いただいたアドレスにQRコードを記載したメールが送付されます。
      万が一、メールが届かない場合は、 <a (click)="bounce()" class="clickable-link"><u>こちらのページをご参照ください。</u></a><br>
      ※Please check the contents and click the Register button.
      After registration, an e-mail with the QR code will be sent to your registered address within a few hours.
      <a (click)="bounce()" class="clickable-link"><u>In case you do not receive the e-mail, please refer to this page.</u></a></a>
      <mat-card-content>
        <!-- <div class="field-container">
          <mat-form-field>
            <mat-label i18n="@@optionalDescription">Optional Description</mat-label>
            <textarea
              matInput
              rows="10"
              disabled
            ></textarea>
          </mat-form-field>
        </div> -->
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@surname">Given Name *</mat-label> -->
            <mat-label>姓 / Surname *</mat-label>            
            <input
              matInput
              formControlName="surname"
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['surname'].invalid"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
          </mat-form-field>
          <span class="spacer" *ngIf="!(bs.isHandset$ | async)"></span>
          <mat-form-field>
            <!-- <mat-label i18n="@@givenName">First Name</mat-label> -->
            <mat-label>名 / Given Name *</mat-label>  
            <input 
              matInput 
              formControlName="given_name" 
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['given_name'].invalid"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@email">Email</mat-label> -->
            <mat-label>メールアドレス / Email *</mat-label>
            <input
              matInput
              formControlName="email"
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['email'].hasError('required')"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
            <mat-error
              *ngIf="fgAttendee.controls['email'].hasError('email')"
              i18n="@@plsInputEmail"
              >Please input an email in the proper format</mat-error
            >
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@companyName">Company Name</mat-label> -->
            <mat-label>企業･団体名 / Company Name *</mat-label>            
            <input
              matInput
              formControlName="company"
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['company'].invalid"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field class="whole">
            <!-- <mat-label i18n="@@division">Division</mat-label> -->
            <mat-label>部署名 / Division</mat-label>
            <input 
              matInput 
              formControlName="division" 
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@jobTitle">Job Title</mat-label> -->
            <mat-label>役職 / Job Title</mat-label>
            <input
              matInput
              formControlName="title"
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
          <!-- <mat-label>〒</mat-label> -->
          <mat-label>郵便番号 / Zip * (ハイフン無し / Without hyphen)</mat-label>
            <input
              matInput
              formControlName="postcode"
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['postcode'].invalid"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@address">Address</mat-label> -->
            <mat-label>住所 / Address *</mat-label>
            <input
              matInput
              formControlName="address"
              [readonly]="true"
            />
            <mat-error
              *ngIf="fgAttendee.controls['address'].invalid"
              i18n="@@fieldRequired"
              >This field is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field>
            <!-- <mat-label i18n="@@telNo">Phone No.</mat-label> -->
            <mat-label>電話番号 / Phone Number  (ハイフン無し / Without hyphen)</mat-label>            
            <input
              matInput
              formControlName="phone"
              [readonly]="true"
            />
          </mat-form-field>
        </div><br>
        <!-- <p i18n="@@handlingPersonal">Handling of Personal Information</p> -->
        <p>個人情報の取り扱いについて / Handling of Personal Information</p>
        <div class="field-container">
          <div matInput [innerHTML]=this.fgPersonalInfo.value readonly>

          </div>
          <!-- <mat-form-field appearance="fill"> -->
            <!-- <textarea rows="10" matInput readonly i18n="@@termsCondition">
In order to handle our customers' important personal information properly and safely in the future when conducting marketing activities and other businesses, we have established this Personal Information Protection Policy as a standard of conduct to be observed by all of our directors and employees. In addition, with respect to the handling of personal information obtained by the Company, the Company will comply with the Act on the Protection of Personal Information, guidelines and other guidelines concerning the protection of personal information, and other relevant laws and regulations concerning the protection of personal information.

Proper Management of Personal Information

We will strive to prevent unauthorized access to, loss, destruction, falsification, and leakage of personal information by implementing appropriate security measures for the management of personal information, and will take appropriate preventive and corrective measures. We will not disclose or provide personal information obtained by us to any third party without the consent of the person concerned, unless there is a legitimate reason to do so, such as when required by law.

2. Compliance with acquisition of personal information, etc.

The following items shall be observed in the acquisition, use, provision, and disposal of personal information by the Company.

            </textarea> -->
              <!-- <textarea rows="10" matInput readonly>
マーケティング活動等事業を行うにあたり、将来に亘って、お客様の大切な個人情報を適正、安全に取り扱うため、役員・従業者のすべてが遵守すべき行動基準として本個人情報保護方針を定め、その遵守の徹底を図ってまいります。また、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
              
1.個人情報の適正な管理
              
当社は、個人情報の管理に関して、適切な安全対策を講じることにより、個人情報に関する不正アクセス、紛失、破壊、改ざん及び漏洩を防止し、適切な予防策及び是正措置を講じることに努めます。また、法令に基づく場合など正当な事由の無い限り当社が取得した個人情報をご本人の同意を得ることなく第三者に対し、開示・提供することはいたしません。
             
2.個人情報の取得等の遵守事項
              
当社による個人情報の取得、利用、提供および廃棄については、以下の事項を遵守します。

In order to handle our customers' important personal information properly and safely in the future when conducting marketing activities and other businesses, we have established this Personal Information Protection Policy as a standard of conduct to be observed by all of our directors and employees. In addition, with respect to the handling of personal information obtained by the Company, the Company will comply with the Act on the Protection of Personal Information, guidelines and other guidelines concerning the protection of personal information, and other relevant laws and regulations concerning the protection of personal information.
              
1. Proper Management of Personal Information
              
We will strive to prevent unauthorized access to, loss, destruction, falsification, and leakage of personal information by implementing appropriate security measures for the management of personal information, and will take appropriate preventive and corrective measures. We will not disclose or provide personal information obtained by us to any third party without the consent of the person concerned, unless there is a legitimate reason to do so, such as when required by law.
              
2. Compliance with acquisition of personal information, etc.
              
The following items shall be observed in the acquisition, use, provision, and disposal of personal information by the Company.
              
            </textarea> -->
          <!-- </mat-form-field>           -->
        </div>
        <div class="field-container">
          <!-- <mat-checkbox color="primary"
          [checked]="true" [disabled]="true"
          i18n="@@agreeHandling">I agree to the handling of personal information.
          </mat-checkbox> -->
          <mat-checkbox color="primary"
          [checked]="true" [disabled]="true"
          >
          個人情報の取り扱いについて同意する / I agree to the handling of personal information. *
          </mat-checkbox>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <!-- <button
        mat-flat-button
        (click)="goBack()"
        class="for-button"
        i18n="@@fix"
        color="accent"
        >Cancel</button> -->
        <button
        mat-flat-button
        (click)="goBack()"
        class="for-button"
        color="accent"
        >< 修正 / Edit </button>
        <button
        mat-flat-button
        type="submit"
        class="for-button"
        color="primary"
        >決定 / Submit ></button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
<mat-toolbar></mat-toolbar>
</body>