<mat-toolbar>
    <span></span>
</mat-toolbar>
<div class="container">
    <mat-card>
        <section>
            <mat-card-title>
                ERROR 404
            </mat-card-title><br>
        </section>
    </mat-card>
</div>