import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Guest } from 'src/app/models/guest';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';
import { Event } from '../models/event';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  guests$ = new BehaviorSubject<{ [key: string]: Guest }>({});

  constructor(private http: HttpClient, private eventServ: EventService) {
    this.refreshGuestList().subscribe();
  }

  refreshGuestList() {
    return this.eventServ.selectedEvent$.pipe(
      filter((event) => !!event),
      take(1),
      switchMap((event) => {
        return this.getAllGuestsByEventId(event!.id);
      }),
      tap((guests) => {
        this.guests$.next(guests);
      })
    );
  }

  addGuest(guest: Guest): Observable<null | undefined> {
    return this.eventServ.selectedEvent$.pipe(
      take(1),
      switchMap((event) => {
        if (!event) return of(undefined);
        return this.http
          .post<null>(`${environment.apiUrl}/event/${event.id}/guest`, guest)
          .pipe(
            take(1),
            switchMap(() => {
              return this.refreshGuestList();
            }),
            map(() => null)
          );
      })
    );
  }

  registerGuest(guest: Guest, event_id: string, locale: string): Observable<null | undefined> {
    let params: { [key: string]: any } = { 
      isRegistration: true,
      regEmail: true,
      locale
     };
    return this.http
      .post<null>(`${environment.apiUrl}/event/${event_id}/guest`, guest,{
        params
      })
      .pipe(
        take(1)
      );
  }

  editGuest(guest: Partial<Guest>): Observable<null | undefined> {
    return this.eventServ.selectedEvent$.pipe(
      take(1),
      switchMap((event) => {
        if (!event || !guest.id) return of(undefined);
        return this.http
          .patch<null>(
            `${environment.apiUrl}/event/${event.id}/guest/${guest!.id}`,
            guest
          )
          .pipe(
            take(1),
            switchMap(() => {
              return this.refreshGuestList();
            }),
            map(() => null)
          );
      })
    );
  }

  getAllGuestsByEventId(id: number): Observable<{ [key: string]: Guest }> {
    return this.http
      .get<Guest[]>(`${environment.apiUrl}/event/${id}/guest`)
      .pipe(
        take(1),
        map((guests) => {
          return guests.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {} as { [key: string]: Guest });
        })
      );
  }

  deleteGuests(event: Event, id: number[]): Observable<null> {
    // const guests = id.reduce((acc, curr) => {
    //   if (!acc) return curr + '';
    //   return acc + ',' + curr;
    // }, '');

    const deleteGuest = (id: number) => {
      return this.http
        .delete<null>(`${environment.apiUrl}/event/${event.id}/guest/${id}`)
        .pipe(take(1));
    };

    return forkJoin(id.map((id) => deleteGuest(id))).pipe(
      take(1),
      // switchMap(() => {
      //   return this.guests$.pipe(take(1));
      // }),
      switchMap(() => {
        return this.refreshGuestList();
      }),
      map(() => null)
      // filter((guests) => !!guests),
      // tap((guests) => {
      //   const newGuests = Object.values(guests!)
      //     .filter((guest) => !id.includes(guest.id))
      //     .reduce((acc, curr) => {
      //       return { ...acc, [curr.id]: curr };
      //     }, {} as { [key: string]: Guest });

      //   this.guests$.next(newGuests);
      // }),
      // map(() => null)
    );

    // return this.http
    //   .delete<null>(`${environment.apiUrl}/event/${event.id}/guest/3`, {
    //     // params: new HttpParams().appendAll({ guests }),
    //   })
    //   .pipe(
    //     take(1),
    //     switchMap(() => {
    //       return this.guests$.pipe(take(1));
    //     }),
    //     filter((guests) => !!guests),
    //     tap((guests) => {
    //       const newGuests = Object.values(guests!)
    //         .filter((guest) => !id.includes(guest.id))
    //         .reduce((acc, curr) => {
    //           return { ...acc, [curr.id]: curr };
    //         }, {} as { [key: string]: Guest });

    //       this.guests$.next(newGuests);
    //     }),
    //     map(() => null)
    //   );
  }

  clearGuestReceptionTime(id: number): Observable<null>{
    return this.http
        .delete<null>(`${environment.apiUrl}/attendance/${id}`)
        .pipe(
          take(1),
          switchMap(() => {
            return this.refreshGuestList();
          }),
          map(() => null)
        );
  }
}
